<template>
  <b-modal
    id="gallery-modal"
    title="Galeria"
    size="xl"
    cancel-variant="danger"
    ok-title="Aceptar"
    cancel-title="Cancelar"
    @ok="done"
    @hidden="selected = []"
    @cancel="selected = []"
  >
    <div v-if="upload">
      <p>Subiendo</p>
      <b-progress
        v-if="upload"
        v-model="upload"
        max="100"
        variant="primary"
        class="progress-bar-primary mb-1"
        animated
      />
    </div>
    <b-col
      v-if="previewImg"
      md="12"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="previewImg = null;picture= null; "
      >
        Cancelar Subida
      </b-button>
      <b-button
        variant="primary ml-1"
        @click="onUpload"
      >
        Subir
      </b-button>
    </b-col>
    <b-row>
      <b-col
        v-if="!previewImg"
        xl="2"
        md="4"
      >
        <div class="input-upload">
          <input
            id="newpicture"
            type="file"
            name="newpicture"
            accept="image/x-png,image/jpeg"
            @change="preview"
          >
        </div>
      </b-col>
      <b-col
        v-if="previewImg"
        xl="2"
        md="4"
      >
        <img
          class="img-slc previewNew"
          :src="previewImg"
          alt="previewNew"
        >
      </b-col>
      <b-col
        v-for="(img, i) in allImages"
        :key="i"
        xl="2"
        md="4"
        :class="(selected.indexOf(img) != -1) ? 'selected' : ''"
        @click="(selected.indexOf(img) != -1) ? selected.splice(selected.indexOf(img), 1) : selected.push(img)"
      >
        <img
          class="img-slc cursor-pointer"
          :src="img"
          alt=""
        >
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import {
  BProgress, BModal, BCol, BRow, BButton,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
    BProgress, BModal, BCol, BRow, BButton,
  },
  data() {
    return {
      picture: null,
      upload: 0,
      allImages: [],
      selected: [],
      previewImg: null,
    }
  },
  mounted() {
    this.listGallery()
  },
  methods: {
    listGallery() {
      this.allImages = []
      const storageRef = firebase.storage().ref('/')
      const listRef = storageRef.child('bodega-herramientas')
      listRef.listAll()
        .then(res => {
          // res.prefixes.forEach(folderRef => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          // })
          res.items.forEach(itemRef => {
          // All the items under listRef.
            itemRef.getDownloadURL().then(url => {
              this.allImages.push(url)
            })
          })
        }).catch(error => {
        // Uh-oh, an error occurred!
          console.error(error)
        })
    },
    onUpload() {
      const storageRef = firebase.storage().ref(`bodega-herramientas/${this.picture.name}`).put(this.picture)
      storageRef.on('state_changed', snapshot => {
        this.upload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, error => {
        console.log(error.message)
      },
      () => {
        storageRef.snapshot.ref.getDownloadURL().then(url => {
          console.log(url)
          this.upload = 0
          this.previewImg = null
          this.picture = null
          this.listGallery()
        })
      })
    },
    preview(event) {
      // eslint-disable-next-line prefer-destructuring
      this.picture = event.target.files[0]
      this.previewImg = URL.createObjectURL(this.picture)
    },
    done() {
      this.$emit('gallery', this.selected)
      this.selected = []
    },
  },
}
</script>
<style>
img.img-slc{
  height: 10rem;
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}
div.selected img{
  border-color:#FE6F17 !important;
  border-width: 2px;
  border-style: solid;
}
.input-upload{
  border: 2px dashed #FE6F17 !important;
  border-style: dashed;
  height: 10rem;
  width: 100%;
  border-radius: 8px;
  position:relative;
}
#newpicture{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0;
  left: 0;
  cursor: pointer;
}
.input-upload::after{
  content: '+';
  color: #FE6F17;
  font-size: 42px;
  margin: auto;
  position: absolute;
  left: 45%;
  right: 50%;
  top: 2.5rem;
}
.previewNew{
  border-color:#FE6F17 !important;
  border-width: 2px;
  border-style: dashed;
}
.progress-bar-striped{
  background-repeat: repeat;
}
</style>
