<template>
  <b-modal
    id="tools-modal"
    title="Editar"
    size="lg"
    cancel-variant="danger"
    ok-title="Aceptar"
    cancel-title="Cancelar"
    @ok="newItem"
  >
    <b-row>
      <b-col md="6">
        <b-form-group label="Descripcion">
          <b-form-input
            v-model="tool.desc"
            type="text"
            autocomplete="off"
            placeholder="Descripcion"
          />
        </b-form-group>
        <b-form-group label="Código">
          <b-form-input
            v-model="tool.code"
            type="text"
            autocomplete="off"
            placeholder="Código"
          />
        </b-form-group>
        <b-row v-if="false">
          <b-col md="6">
            <b-form-group label="COD. Antiguo">
              <b-form-input
                v-model="tool.oldcode"
                type="text"
                autocomplete="off"
                placeholder="COD. Antiguo"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Código">
              <b-form-input
                v-model="tool.code"
                type="text"
                autocomplete="off"
                placeholder="Código"
              />
            </b-form-group></b-col>
        </b-row>

        <b-form-group label="Marca">
          <b-form-input
            v-model="tool.marca"
            type="text"
            autocomplete="off"
            placeholder="Marca"
          />
        </b-form-group>
        <b-form-group label="Medida">
          <b-form-input
            v-model="tool.size"
            type="text"
            autocomplete="off"
            placeholder="Medida"
          />
        </b-form-group>
        <b-form-group
          v-if="false"
          label="Modelo"
        >
          <b-form-input
            v-model="tool.modelo"
            type="text"
            autocomplete="off"
            placeholder="Modelo"
          />
        </b-form-group>
        <b-form-group label="Categoria">
          <b-form-input
            v-model="tool.cat"
            type="text"
            autocomplete="off"
            placeholder="Categoria"
          />
        </b-form-group>
        <b-form-group label="Ubicación">
          <b-form-input
            v-model="tool.ubi"
            type="text"
            autocomplete="off"
            placeholder="Ubicación"
          />
        </b-form-group>
        <!-- Si es unico / single -->
        <b-form-group label="Unico">
          <b-form-checkbox
            id="checkbox-1"
            v-model="tool.single"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            Unico
          </b-form-checkbox>
        </b-form-group>
        <!-- Stock -->
        <b-form-group
          v-if="!tool.single"
          label="Stock"
        >
          <b-form-input
            v-model="tool.stock"
            type="text"
            autocomplete="off"
            placeholder="Stock"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-button
          class="mb-2"
          variant="info"
          @click="$bvModal.show('gallery-modal')"
        >
          <feather-icon
            icon="ImageIcon"
            class="inline-block"
          /> Galeria
        </b-button>
        <b-row>
          <b-col
            v-for="(img, i) in tool.img"
            :key="i"
            md="6"
          >
            <div class="relative">
              <span
                class="delete-image"
                @click="removeImage(i)"
              >x</span>
              <img
                :src="img"
                :alt="i"
                class="images-list"
              >
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <galeria @gallery="updateGallery" />
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, BButton,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import galeria from '../../Components/Upload.vue'
import 'firebase/database'

export default {
  components: {
    BModal, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, galeria, BButton,
  },
  props: {
    tool: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'herramientas',
    },
  },
  methods: {
    updateGallery(event) {
      const initial = this.tool.img
      this.tool.img = initial.concat(event)
    },
    removeImage(img) {
      this.tool.img.splice(img, 1)
    },
    newItem() {
      const ref = `bodegas/${this.type}`
      if (!this.tool.key) {
        firebase.database().ref(ref).push(this.tool)
        this.$bvToast.toast('Elemento creado correctamente.', {
          title: 'Creado',
          variant: 'success',
          solid: true,
        })
      } else {
        firebase.database().ref(`${ref}/${this.tool.key}`).update(this.tool)
        this.$bvToast.toast('Elemento actualizado correctamente.', {
          title: 'Actualizado',
          variant: 'success',
          solid: true,
        })
      }
    },
  },
}
</script>
<style>
.images-list{
  height: 12rem;
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}
.delete-image{
  border-radius: 50%;
  background: red;
  padding: 6px;
  position: absolute;
  line-height: 7px;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
</style>
