<template>
  <b-card
    v-if="qty"
  >
    <b-row>
      <b-col
        v-if="tool.img"
        sm="6"
      >
        <img
          :src="tool.img[0]"
          :alt="tool.desc"
          class="previewTool"
        >
      </b-col>
      <b-col sm="6">
        <h4>{{ tool.desc }}</h4>
        <p>Codigo: {{ tool.code }}</p>
        <p>Cantidad: {{ qty }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCol, BRow, BCard } from 'bootstrap-vue'

export default {
  name: 'SingleTool',
  components: { BCol, BRow, BCard },
  props: {
    tool: {
      type: Object,
      default: () => {},
      required: true,
    },
    qty: {
      type: Number,
      default: 0,
      required: true,
    },
    colaborador: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
