<template>
  <b-modal
    id="tools-control-modal"
    :title="isReturn ? 'Devolución' : 'Control v2'"
    size="md"
    cancel-variant="danger"
    ok-title="Aceptar"
    cancel-title="Cancelar"
    @cancel="closeAndClear"
    @hidden="closeAndClear"
    @ok="saveControl"
  >
    <v-autocomplete
      v-model="colabSelected"
      :items="listUsers"
      variant="outlined"
      item-text="name"
      item-value="value"
      label="No. Ficha"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        {{ data.item.name }}
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-if="colabSelected && !isReturn && !onlyView"
      :search-input.sync="herramientaSelected"
      :items="listHerramientas"
      variant="outlined"
      item-text="name"
      item-value="value"
      label="Herramienta"
      @change="addElement"
      @input="herramientaSelected=null"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        {{ data.item.name }}
      </template>
    </v-autocomplete>
    <b-form-group>
      <b-form-textarea
        v-if="colabSelected && !onlyView && elementos.length"
        v-model="comment"
        placeholder="Comentario..."
      />
    </b-form-group>
    <b-card
      v-for="(tool, ix) in elementos"
      :key="ix"
    >
      <b-row>
        <b-col
          v-if="tools[tool].img"
          sm="6"
        >
          <img
            :src="tools[tool].img[0]"
            :alt="tools[tool].desc"
            class="previewTool"
          >
        </b-col>
        <b-col sm="6">
          <h4>{{ tools[tool].desc }}</h4>
          <p>Codigo: {{ tools[tool].code }}</p>
          <b-button
            variant="danger"
            @click="elementos.splice(ix, 1)"
          ><feather-icon
            icon="XIcon"
            class="inline"
          /> Remover</b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="onlyView && colabSelected">
      <ToolSingle
        v-for="(tool, ix) in ocupados"
        :key="ix"
        :qty="tool[colabSelected]"
        :tool="tools[ix]"
        :colaborador="colabSelected"
      />
      <ToolSingle
        v-for="(tool, ix) in ocupados"
        :key="'USEROLDKEYS'+ix"
        :qty="tool[objUsersOld[colabSelected]]"
        :tool="tools[ix]"
        :colaborador="objUsersOld[colabSelected]"
      />
    </div>
    <div v-if="isReturn && colabSelected">
      <template
        v-for="(tool, ix) in ocupadosArr"
      >
        <div :key="ix">
          <template v-if="tool.indexOf(colabSelected) !== -1 || (tool.indexOf(objUsersOld[colabSelected]) !== -1)">
            <div
              v-for="(t, i) in tool"
              :key="i"
            >
              <b-card v-if="t === colabSelected || t === objUsersOld[colabSelected]">
                <b-row>
                  <b-col
                    v-if="tools[ix].img"
                    sm="6"
                  >
                    <img
                      :src="tools[ix].img[0]"
                      :alt="tools[ix].desc"
                      class="previewTool"
                    >
                  </b-col>
                  <b-col sm="6">
                    <h4>{{ tools[ix].desc }}</h4>
                    <p>Codigo: {{ tools[ix].code }}</p>
                    <b-button
                      v-if="recibidos.includes(ix+'%'+i)"
                      :name="'button_remove'+ix"
                      variant="danger"
                      @click="recibidos.splice(recibidos.indexOf(ix+'%'+i), 1)"
                    ><feather-icon
                      icon="XIcon"
                      class="inline"
                    /> Cancelar</b-button>
                    <b-button
                      v-if="!recibidos.includes(ix+'%'+i)"
                      :name="'button_receive'+ix"
                      variant="success"
                      @click="recibidos.push(ix+'%'+i)"
                    ><feather-icon
                      icon="CheckIcon"
                      class="inline"
                    /> Recibir</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </template>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BCard, BCol, BRow, BButton, BFormTextarea,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import ToolSingle from './ToolSingle.vue'
import usersJSON from './usersOld.json'
import 'firebase/database'

export default {
  components: {
    BModal, BFormGroup, BCard, BCol, BRow, BButton, BFormTextarea, ToolSingle,
  },
  props: {
    tools: {
      type: Object,
      required: true,
      default: () => {},
    },
    users: {
      type: Object,
      required: true,
      default: () => {},
    },
    isReturn: {
      type: Boolean,
      required: true,
      default: false,
    },
    onlyView: {
      type: Boolean,
      required: true,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'herramientas',
    },
  },
  data() {
    return {
      colabSelected: null,
      // Opciones para filtrar herrramientas
      herramientaSelected: null,
      elementos: [],
      //
      recibidos: [],
      ocupados: {},
      ocupadosArr: {},
      comment: '',
      dbRef: '',
      listHerramientas: null,
      // Temporales
      objUsersOld: null,
    }
  },
  computed: {
    listUsers: {
      get() {
        const result = []
        Object.keys(this.users).forEach(element => {
          const item = { value: element, name: `${element} - ${this.users[element]}` }
          result.push(item)
        })
        return result
      },
    },
  },
  watch: {
    tools() {
      if (this.tools !== null) {
        this.orderTools()
      }
    },
  },
  created() {
    if (this.tools !== null) {
      this.orderTools()
    }
  },
  methods: {
    orderTools() {
      this.dbRef = `bodegas/${this.type}`
      const result = []
      this.ocupadosArr = {}
      this.ocupados = {}
      Object.keys(this.tools).forEach(k => {
        const element = this.tools[k]
        const item = { value: k, name: `${element.code} - ${element.desc}` }
        if (element.in_use && this.viewAvaliable(element.in_use.length, element.stock)) {
          result.push(item)
        }
        // Almacenar las que esten en uso
        const inUse = element.in_use ? element.in_use : []
        if (inUse.length) {
          this.ocupados[k] = inUse.reduce((prev, cur) => ((prev[cur] = (prev[cur] ?? 0) + 1), prev), {})
          this.ocupadosArr[k] = inUse
        }
      })
      this.objUsersOld = usersJSON
      this.listHerramientas = result
    },
    closeAndClear() {
      this.colabSelected = null
      this.herramientaSelected = null
      this.elementos = []
      this.filteredOptions = []
      this.comment = ''
    },
    viewAvaliable(uso, stock) {
      const usoTemp = !uso ? 0 : uso
      if (usoTemp >= stock) {
        return 0
      }
      return stock - usoTemp
    },
    addElement(key) {
      if (key === '' || key === undefined || key === null) {
        return
      }
      this.herramientaSelected = null
      const counts = {}
      this.elementos.forEach(x => { counts[x] = (counts[x] || 0) + 1 })
      const disponible = this.viewAvaliable(this.tools[key].in_use.length, this.tools[key].stock)
      if (!counts[key]) {
        this.elementos.push(key)
        return
      }
      if (counts[key] < disponible) {
        this.elementos.push(key)
      }
    },
    saveControl() {
      if (this.elementos.length && this.colabSelected && !this.isReturn) {
        // eslint-disable-next-line no-return-assign
        const resultado = this.elementos.reduce((prev, cur) => ((prev[cur] = (prev[cur] ?? 0) + 1), prev), {})
        Object.keys(resultado).forEach(t => {
          firebase.database().ref(`${this.dbRef}/${t}`).once('value', snap => {
            const newStock = this.updateStockSingle(resultado[t], snap.val().in_use)
            firebase.database().ref(`${this.dbRef}/${t}/in_use`).set(newStock)
            // History
            this.addHistory(t, 'Retiro', newStock)
          })
        })
      } else {
        console.log('Error')
        return
      }
      if (this.isReturn) {
        Object.values(this.recibidos).forEach(t => {
          const myKey = t.split('%')
          const itemKey = myKey[0]
          firebase.database().ref(`${this.dbRef}/${itemKey}`).once('value', snap => {
            const currentItems = snap.val().in_use
            const index = currentItems.indexOf(this.colabSelected)
            currentItems.splice(index, 1)
            const result = currentItems.length ? currentItems : 0
            firebase.database().ref(`${this.dbRef}/${itemKey}/in_use`).set(result)
            // History
            this.addHistory(itemKey, 'Ingreso', this.colabSelected)
            this.recibidos = []
          })
        })
      }
    },
    addHistory(itemKey, action, colab) {
      firebase.database().ref(`history/${itemKey}`).push({
        action,
        colab,
        comment: this.comment,
        date: new Date().getTime(),
      })
    },
    updateStockSingle(counter, used) {
      const keyColab = this.colabSelected

      if (used.length) {
        const repetitions = counter >= 2 ? counter - 1 : 0
        used.push(...Array(repetitions).fill(keyColab))
        used.push(keyColab)
        return used
      }

      const temporal = []
      if (counter >= 2) {
        temporal.push(...Array(counter - 1).fill(keyColab))
        temporal.push(keyColab)
        return temporal
      }

      temporal.push(keyColab)
      return temporal
    },
  },
}
</script>
<style>
.previewTool{
  height: 8rem;
  width: 100%;
  object-fit: cover;
}
.dark-layout #tools-control-modal .card {
    background-color: #161d31;
}
</style>
