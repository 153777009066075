<template>
  <b-modal
    id="users-tools-modal"
    title="Usuarios v2"
    size="lg"
    cancel-variant="danger"
    ok-title="Aceptar"
    cancel-title="Cancelar"
  >
    <b-row>
      <b-col md="4">
        <b-form-input
          v-model="ficha"
          type="number"
          autocomplete="off"
          placeholder="Ficha"
        />
      </b-col>
      <b-col md="5">
        <b-form-input
          v-model="name"
          type="text"
          autocomplete="off"
          placeholder="Nombre"
        />
      </b-col>
      <b-col
        md="3"
        class="text-right"
      >
        <b-button
          variant="success"
          @click="newUser"
        >Agregar Usuario</b-button>
      </b-col>
      <b-col md="12">
        <b-form-input
          v-model="search"
          type="text"
          autocomplete="off"
          placeholder="Buscar"
        />
      </b-col>
      <template v-for="(item, index) in users">
        <b-col
          v-show="findUser(item+' '+index)"
          :key="index"
          md="6"
        >
          <b-button
            class="mr-1 btn-icon"
            variant="danger"
            @click="remove(index, item)"
          ><feather-icon icon="TrashIcon" /></b-button>
          {{ index }} - {{ item }}
        </b-col>
      </template>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  components: {
    BModal, BFormInput, BRow, BCol, BButton,
  },
  props: {
    users: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: null,
      ficha: null,
      search: '',
    }
  },
  methods: {
    newUser() {
      if (this.name && this.ficha) {
        firebase.database().ref(`bodegas/usrs/${this.ficha}`).set(this.name)
        this.$bvToast.toast('Usuario creado correctamente.', {
          title: 'Usuario Agregado',
          variant: 'success',
          solid: true,
        })
        this.name = null
        this.ficha = null
      }
    },
    remove(user, name) {
      this.$bvModal
        .msgBoxConfirm(`Por favor confirmar que desea eliminar al usuario ${name}`, {
          title: 'Confirmar',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            firebase.database().ref(`bodegas/usrs/${user}`).remove().then(() => {
              this.$bvToast.toast('Usuario eliminado correctamente.', {
                title: 'Usuario Eliminado',
                variant: 'danger',
                solid: true,
              })
            })
          }
        })
    },
    findUser(user) {
      const busqueda = this.search ? this.search.toUpperCase() : ''
      const theUser = user.toUpperCase()
      if (busqueda) {
        if (theUser.indexOf(busqueda) != -1) {
          return true
        }

        return false
      }
      return true
    },
  },
}
</script>
<style>
.images-list{
  height: 12rem;
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}
</style>
